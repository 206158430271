.App {
  text-align: center;
  width: '100%';
  font-family: 'Poppins';
  background:#F4F6F6;
  user-select: none; 
  -webkit-user-select: none;
  -ms-user-select: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body, html { 
  font-family: 'Poppins', sans-serif;
}

html, body, #root, .App {
  height: 100%;
  width: 100%;
  background:#F4F6F6;
 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





.gd-carousel-wrapper {
  position:relative;
 

}

.gd-carousel {
  position:unset;
 
  

  .react-multiple-carousel__arrow {
      position:absolute;
  }

  .react-multiple-carousel__arrow--left {
      left: calc(-1% );
      
      
  }

  .react-multiple-carousel__arrow--right {
      right: calc(-1%)
  }
} 
.arrow {
  position: absolute;
 
}

.right {
  right: 0;
}

.left {
  left: 0;
}
.MuiFormHelperText-root {
  color: red !important;
}

.slider {
  width: 100%;
  max-width: 100%;
 
  overflow: hidden;
  background-color: white;
}

.slider-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.slider-controls button {
  font-size: 1.5rem;
  padding: 5px 10px;
  background: none;
  border: none;
  cursor: pointer;

}

.slider-controls button:focus {
  outline: none;
}

.slider-container {
  display: flex;
  transition: transform 0.3s ease;
}

.slider-wrapper {
  display: flex;
}

.slider-item {
  flex: 0 0 120px;

  border-radius: 5px;
  height: 120px;
  overflow: hidden;


  
}

.slider-item img {
  width:80px;
  height: 80px;
 
  object-fit: cover;
}

.name {
  padding: 5px;
  text-align: center;
 
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}




.highcharts-contextmenu {
  display: none !important;
}